import React, {
  FC,
  useState,
  useEffect,
  ChangeEventHandler,
  KeyboardEventHandler,
  FormEventHandler,
} from "react";
import { SearchField } from "../../../../react-components/Inputs/SearchField/SearchField";
import { ProductListingResult } from "./ProductListingResult.csharp";
import { FocusOn } from "react-focus-on";

interface ProductListingSearchProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler;
  onReset: FormEventHandler;
  searchResults: ProductListingResult[];
  value: string;
}

const ProductListingSearch: FC<ProductListingSearchProps> = ({
  onSubmit,
  onChange,
  onReset,
  searchResults,
  value,
}) => {
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);

  const closeDropdown = () => {
    setIsDropdownExpanded(false);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.value) {
      closeDropdown();
    }
    onChange(e);
  };

  // TODO Better handle keyboard navigation
  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    switch (e.key) {
      case "Escape":
        setIsDropdownExpanded(false);
        break;
      default:
        break;
    }
  };

  const onEscape: KeyboardEventHandler<HTMLUListElement> = (e) => {
    if (e.key !== "Escape") return;
    closeDropdown();
  };

  useEffect(() => {
    if (searchResults?.length) {
      setIsDropdownExpanded(true);
    }
  }, [value, searchResults]);

  return (
    <form className="ProductListingSearch__searchBar">
      <FocusOn enabled={isDropdownExpanded} onClickOutside={closeDropdown} returnFocus scrollLock>
        <SearchField
          value={value}
          onChange={handleChange}
          onSubmit={onSubmit}
          onReset={onReset}
          onKeyUp={onKeyUp}
          placeholderText="Search for products in this category..."
          className="ProductListingSearch__searchInput"
          aria-expanded={isDropdownExpanded}
          aria-controls="suggestionDropdown"
        />
        {isDropdownExpanded && (
          <ul
            className="ProductListingSearch__searchDropdown"
            role="menu"
            id="suggestionDropdown"
            onKeyDown={onEscape}
          >
            {searchResults?.map((result) => (
              <li key={result.name} className="ProductListingSearch__searchResult">
                <a href={result.url}>{result.name}</a>
              </li>
            ))}
          </ul>
        )}
      </FocusOn>
    </form>
  );
};

export default ProductListingSearch;
