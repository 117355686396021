import React, { FC, useState, useEffect, ChangeEventHandler, FormEventHandler } from "react";
import { ProductsListingViewModel } from "./ProductsListingViewModel.csharp";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { ProductsListingFilters } from "./ProductsListingFilters/ProductsListingFilters";
import { FiltersState } from "./ProductsListing.types";
import {
  getFiltersDefaultState,
  mapFiltersStateToUrlParams,
  mapFiltersToFiltersState,
  mapUrlParamsToFiltersState,
} from "./ProductsListing.utils";
import { ProductsListingTopBar } from "./ProductsListing.TopBar";
import { useIsBelowScreenSize } from "../../../react-components/useIsBelowScreenSize";
import { CheckboxGroupOption } from "../../../react-components/Inputs/CheckboxGroup/CheckboxGroup";
import { ProductsListingProducts } from "./ProductsListingProducts/ProductsListingProducts";
import { ParamType, useSearchParams } from "../../../react-components/useSearchParams";
import { camelCase, debounce } from "lodash";
import { TileGrid } from "../../../react-components/TileGrid/TileGrid";
import { ProductListingResult } from "./ProductListingSearch/ProductListingResult.csharp";
import ProductListingSearch from "./ProductListingSearch/ProductListingSearch";

const ProductsListing: FC<ComponentProps<ProductsListingViewModel>> = ({
  model: { heading, introduction, products, filters, productCardPictureProfile, pageId },
}) => {
  const filtersMapped = mapFiltersToFiltersState(filters);
  const defaultFiltersState = getFiltersDefaultState(filtersMapped);

  const [filtersState, setFilters] = useState<FiltersState>(defaultFiltersState);
  const [showFiltersPanel, setShowFiltersPanel] = useState(true);
  const isMobile = useIsBelowScreenSize("tablet");
  const [searchValue, setSearchValue] = useState("");
  const [selectedResult, setSelectedResult] = useState<ProductListingResult[]>([]);

  const searchResultDebounceValue = 250;
  const validateInput = (input: string) => input?.length >= 2;

  const availableFiltersCount = Object.values(filtersMapped).reduce(
    (prev, curr) => prev + curr.options.length,
    0,
  );

  const getSearchResults = () => {
    if (!validateInput(searchValue)) {
      setSelectedResult([]);
      return;
    }

    fetch(`/api/commerce/products?categoryId=${pageId}&searchTerm=${searchValue}`)
      .then((x) => x.json())
      .then((x) => setSelectedResult(x as ProductListingResult[]))
      .catch(() => setSelectedResult([]));
  };

  const debouncedSearchResult = debounce(getSearchResults, searchResultDebounceValue);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    getSearchResults();
  };

  const handleReset = () => {
    setSearchValue("");
  };

  useEffect(() => {
    debouncedSearchResult();
    return () => debouncedSearchResult.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const { params, setParams, clearParams } = useSearchParams(
    Object.keys(filtersMapped).reduce<Record<keyof typeof filtersMapped, ParamType>>(
      (acc, currentItem) => {
        acc[currentItem] = ParamType.Array;
        return acc;
      },
      {},
    ),
  );

  const selectedFilters = Object.values(filtersState).flat();
  const filtersCount = selectedFilters.length;

  const handleFiltersChange = (key: string, newValues: CheckboxGroupOption[]) => {
    const newFilters = { ...filtersState };
    newFilters[key] = newValues;
    const newParams = mapFiltersStateToUrlParams(newFilters);
    setFilters(newFilters);
    setParams(newParams);
  };

  const handleClearFilters = () => {
    setFilters(defaultFiltersState);
    clearParams();
  };

  const toggleFiltersPanel = () => setShowFiltersPanel((prevValue) => !prevValue);

  const filteredProducts =
    filtersCount > 0
      ? products.filter((p) =>
          selectedFilters.every((f) => p.tags.some((t) => f.value === camelCase(t))),
        )
      : products;

  const productsCount = filteredProducts.length;

  useEffect(() => {
    const newFilters = mapUrlParamsToFiltersState(params, filtersMapped);

    setFilters(newFilters);
    setShowFiltersPanel(availableFiltersCount > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ProductsListing">
      {(heading || introduction) && (
        <div className="ProductsListing__header">
          {heading && <h2 className="ProductsListing__title">{heading}</h2>}
          {introduction && <p className="ProductsListing__description">{introduction}</p>}
        </div>
      )}
      <ProductListingSearch
        onSubmit={handleSubmit}
        onChange={handleSearch}
        onReset={handleReset}
        value={searchValue}
        searchResults={selectedResult}
      />
      <TileGrid
        mainContent={
          <ProductsListingProducts
            products={filteredProducts}
            productCardPictureProfile={productCardPictureProfile}
            fullWidth={!showFiltersPanel}
          />
        }
        topBar={
          <ProductsListingTopBar
            filtersCount={filtersCount}
            onClearFilters={handleClearFilters}
            isMobile={isMobile}
            showFiltersPanel={showFiltersPanel}
            availableFiltersCount={availableFiltersCount}
            onToggleFiltersPanel={toggleFiltersPanel}
            productsCount={productsCount}
          />
        }
        sidePanel={
          showFiltersPanel ? (
            <ProductsListingFilters
              filters={filtersMapped}
              filtersState={filtersState}
              filtersCount={filtersCount}
              onFiltersChange={handleFiltersChange}
              onClearFilters={handleClearFilters}
              isMobile={isMobile}
              showFiltersPanel={showFiltersPanel}
              productsCount={productsCount}
            />
          ) : null
        }
      />
    </div>
  );
};

export default ProductsListing;
